<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-select
          v-model="listQuery.status"
          filterable
          @change="getList()"
          style="width: 75px; margin-left: 0"
          size="small"
        >
          <el-option label="全部" :value="0"></el-option>
          <el-option label="正常" :value="1"></el-option>
          <el-option label="非正常" :value="2"></el-option>
        </el-select>
        <el-select
          v-model="listQuery.jcStatus"
          filterable
          @change="getList()"
          style="width: 85px"
          size="small"
        >
          <el-option label="全部" :value="-1"></el-option>
          <el-option label="6万扣除" :value="1"></el-option>
          <el-option label="正常扣除" :value="0"></el-option>
        </el-select>
        <el-input
          placeholder="请输入姓名/证件号码"
          style="width: 170px"
          clearable
          v-model="listQuery.name"
          size="small"
          @keyup.enter="getList"
        />
        <el-button icon="Search" type="primary" size="small" @click="getList()"
          >搜索</el-button
        >
      </div>
      <div></div>
    </div>
    <el-table
      stripe
      :height="contentStyleObj"
      v-loading="loading"
      :data="list"
      border
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
    <!--   <el-table-column type="selection" align="center" width="40" /> -->
      <el-table-column align="center" type="index" width="60" label="序号" fixed="left">
      </el-table-column>
      <el-table-column
        label="姓名"
        min-width="80"
        prop="name"
        fixed="left"
      ></el-table-column>
      <el-table-column label="子女教育支出扣除信息" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.childrenEducation }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time1 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd1 }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="继续教育支出扣除信息" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.continuingEducation }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time2 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd2 }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="住房贷款利息支出扣除信息" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.housingLoan }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time4 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd4 }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="住房租金支出扣除信息" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.housingRent }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time5 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd5 }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="赡养老人支出扣除信息" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.support }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time6 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd6 }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="3岁以下婴幼儿照护支出扣除信息" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.babyReduction }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time7 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd7 }}
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="个人养老金" align="center">
        <el-table-column label="扣除金额" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.personalPension }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期起" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.time8 }}
          </template>
        </el-table-column>
        <el-table-column label="扣除所属期止" align="center" min-width="130">
          <template #default="{ row }">
            {{ row.timeEnd8 }}
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from "vue";
import { salaryList } from "@/api/salary";
const { proxy } = getCurrentInstance();
const listQuery = ref({
  page: 1,
  limit: 20,
  comId: 0,
  jcStatus: -1,
  status: 1,
  useStatus:-1,
  disability:-1
});
const loading = ref(false);
const list = ref([]);
const total = ref(0);
const contentStyleObj = ref({});

onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  listQuery.value.comId = proxy.$store.getters["commons/params"].deductionId;
  getList();
});
const getList = () => {
  loading.value = true;
  salaryList(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};
</script>

<style lang="scss" scoped>
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.iconStyle {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 18px;
}
.icon-shanchu {
  color: #fe696a;
}
:deep(.el-dropdown-menu__item) {
  display: block;
}
</style>
